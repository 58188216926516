import Head from "next/head";

import { LoginScreen } from "@chapter/nightingale-base";

export default function Login() {
    return (
        <>
            <Head>
                <title>Login | Chapter OTC</title>
            </Head>
            <LoginScreen />
        </>
    );
}
